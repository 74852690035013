export const filterTreeStatic = [
  {
    label: "Payment Method",
    value: "payment_method",
    type: "select",
    initialValue: "openPayd",
    selectOptions: [
      {
        label: "Stripe ",
        value: "stripe",
      },
      {
        label: "OpenPayd",
        value: "openPayd",
      },
      {
        label: "xpate",
        value: "xpate",
      },
      {
        label: "Astropay",
        value: "astropay",
      },
      {
        label: "Volt",
        value: "volt",
      },
      {
        label: "Zimpler",
        value: "zimpler",
      },
      {
        label: "Skrill",
        value: "skrill",
      },
      {
        label: "Paybis",
        value: "paybis",
      },
      {
        label: "Rapid Transfer",
        value: "rapid",
      },
      {
        label: "Neteller",
        value: "neteller",
      },
    ],
  },
  {
    label: "Partner",
    value: "Partner",
    type: "select",
    initialValue: "",
    selectOptions: [],
  },
  {
    label: "Fiat",
    value: "currency",
    initialValue: "EUR",
    type: "select",
    selectOptions: [],
  },

  {
    label: "Date",
    value: "DATE",
    type: "date_picker",
  },
];

export const format = (
  value?: string | number,
  noTruncate?: boolean
): string => {
  const formatter = new Intl.NumberFormat("en-US");
  const numValue = Number(value || 0);
  const fixedValue = noTruncate
    ? Number(numValue || 0)
    : Number((numValue || 0).toFixed(2));

  return formatter.format(fixedValue);
};
