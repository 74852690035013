import { useAuthStateContext } from "providers/Auth";
import { useEffect, useState } from "react";

import Line from "components/Charts/Line";
import FilterList from "components/Filter/FilterList";
import { assetInstance, instance } from "utils/api";
import "./styles.scss";
import PresetsDatePicker from "components/PresetsDatePicker";
import { IPairs, IRevenue } from "./types";

export default function FinancialTransaction(): JSX.Element {
  const { user } = useAuthStateContext();
  const isPartner = user?.role === "partner";
  useEffect(() => {
    const initiateData = async () => {
      const [
        {
          data: { rows: fiatCurrencies },
        },
        {
          data: { rows: cryptoCurrencies },
        },
        {
          data: {
            data: { rows: partners },
          },
        },
        {
          data: { data: countries },
        },
      ] = await Promise.all([
        assetInstance.get(`fiat/list?page=1&limit=200`),
        assetInstance.get("/crypto/admin_crypto_currencies?page=1&limit=200"),
        !isPartner
          ? instance.get("/partners?page=1&limit=100")
          : Promise.resolve({ data: { data: { rows: [] } } }),
        instance.get("/countries"),
      ]);

      const newFilterTree = [...filterTree];
      const fiatObject = newFilterTree.find(
        (el) => el.value === "currency"
      ) || { selectOptions: [] };

      const cryptoObject = newFilterTree.find(
        (el) => el.value === "crypto_currency"
      ) || { selectOptions: [] };

      const partnerObject = newFilterTree.find(
        (el) => el.value === "partner"
      ) || { selectOptions: [] };

      const billingCountryObject = newFilterTree.find(
        (el) => el.value === "billing_country"
      ) || { selectOptions: [] };

      fiatObject.selectOptions = fiatCurrencies.map((el) => ({
        value: el.symbol,
        label: el.symbol,
        sign: el.sign,
      }));
      cryptoObject.selectOptions = cryptoCurrencies.map((el) => ({
        value: el.code,
        label: `${el.symbol}(${el.core})`,
        ...el,
      }));
      partnerObject.selectOptions = partners?.map((el) => ({
        id: el.id,
        value: el.name,
        label: el.name,
      }));
      billingCountryObject.selectOptions = countries?.map((el) => ({
        id: el.id,
        value: el.country,
        label: el.country,
      }));

      setFilterTree(newFilterTree);
    };

    initiateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [filterTreeState, setFilterTreeState] = useState({
    transaction_type: "Buy",
    fiat: { symbol: "EUR", sign: "€" },
    asset: "",
    payment_method: "",
    partner_name: "",
    billing_address: "",
    date: {
      interval: "",
      endDate: "",
      startDate: "",
    },
  });

  useEffect(() => {
    const getData = async () => {
      const chartDataObj = {};

      if (filterTreeState.date.startDate && filterTreeState.date.endDate) {
        const {
          data: { transactionAmountChart, currentData, growthResults, revenue },
        } = await instance.get(`/admin/summary/report`, {
          params: {
            currency: filterTreeState.fiat.symbol
              ? filterTreeState.fiat.symbol
              : undefined,
            asset: filterTreeState.asset ? filterTreeState.asset : undefined,
            pay_method: filterTreeState.payment_method
              ? filterTreeState.payment_method
              : undefined,

            interval: filterTreeState.date.interval,
            fromDate: filterTreeState.date.startDate,
            toDate: filterTreeState.date.endDate,
            partner_id: isPartner
              ? user.id
              : filterTreeState.partner_name
              ? filterTreeState.partner_name
              : undefined,
            country_id: filterTreeState.billing_address
              ? filterTreeState.billing_address
              : undefined,
          },
        });

        setTransactionAmount(transactionAmountChart);
        setGrowthResult(growthResults);
        currentData.forEach((el) => {
          if (chartDataObj[el.asset]) {
            chartDataObj[el.asset].push(el);
          } else {
            chartDataObj[el.asset] = [el];
          }
        });
        setPairs(chartDataObj as typeof pairs);
        setRevenue(revenue);
      }
    };

    getData();
  }, [
    filterTreeState.date.startDate,
    filterTreeState.date.endDate,
    filterTreeState.date.interval,
    filterTreeState.fiat.symbol,
    filterTreeState.partner_name,
    filterTreeState.asset,
    filterTreeState.billing_address,
    filterTreeState.payment_method,

    isPartner,
    user?.id,
  ]);
  const [pairs, setPairs] = useState<IPairs>({});
  const [revenue, setRevenue] = useState<IRevenue>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [growthResult, setGrowthResult] = useState<any[]>([]);

  const [transactionAmount, setTransactionAmount] = useState({
    currentTotal: 0,
    growthPercentage: 0,
    previousTotal: 0,
    totalByCurrency: [],
  });

  const filterTreeStatic = [
    // {
    //   label: "Transaction Type",
    //   value: "transaction_type",
    //   type: "select",

    //   initialValue: "",
    //   selectOptions: [
    //     {
    //       label: "Buy ",
    //       value: "buy",
    //     },
    //     {
    //       label: "Sell",
    //       value: "sell",
    //     },
    //     {
    //       label: "Receive",
    //       value: "receive",
    //     },
    //   ],
    //   setValue: (e: React.ChangeEvent<HTMLInputElement>) =>
    //     setFilterTreeState({
    //       ...filterTreeState,
    //       transaction_type: e.target.value,
    //     }),
    //   clearSearch: () =>
    //     setFilterTreeState({ ...filterTreeState, transaction_type: "" }),
    // },
    {
      label: "Fiat",
      value: "currency",
      initialValue: "EUR",
      type: "select",
      isDisabled: true,
      selectOptions: [],
      setValue: (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFiat = filterTree
          .find((el) => el.label === "Fiat")
          ?.selectOptions.find((el) => el.label === e.target.value) as {
          value: string;
          label: string;
          sign: string;
        };

        setFilterTreeState({
          ...filterTreeState,
          fiat: { symbol: newFiat?.label || "", sign: newFiat?.sign || "" },
        });
      },
      clearSearch: () =>
        setFilterTreeState({
          ...filterTreeState,
          fiat: { symbol: "", sign: "" },
        }),
    },
    {
      label: "Asset",
      value: "crypto_currency",
      initialValue: "",
      type: "select",
      selectOptions: [],
      setValue: (e: React.ChangeEvent<HTMLInputElement>) =>
        setFilterTreeState({
          ...filterTreeState,
          asset: e.target.value,
        }),
      clearSearch: () => setFilterTreeState({ ...filterTreeState, asset: "" }),
    },
    {
      label: "Payment Method",
      value: "payment_method",
      type: "select",
      initialValue: "",
      selectOptions: [
        {
          label: "Stripe ",
          value: "stripe",
        },
        {
          label: "OpenPayd",
          value: "openPayd",
        },

        {
          label: "Astropay",
          value: "astropay",
        },
        {
          label: "Volt",
          value: "volt",
        },
        {
          label: "Zimpler",
          value: "zimpler",
        },
        {
          label: "Skrill",
          value: "skrill",
        },
        {
          label: "Paybis",
          value: "paybis",
        },
        {
          label: "Rapid Transfer",
          value: "rapid",
        },
        {
          label: "Neteller",
          value: "neteller",
        },
      ],
      setValue: (e: React.ChangeEvent<HTMLInputElement>) =>
        setFilterTreeState({
          ...filterTreeState,
          payment_method: e.target.value,
        }),
      clearSearch: () =>
        setFilterTreeState({ ...filterTreeState, payment_method: "" }),
    },
    {
      label: "Billing Country",
      value: "billing_country",
      type: "select",
      initialValue: "",
      selectOptions: [],
      setValue: (e: React.ChangeEvent<HTMLInputElement>) => {
        const foundCountry = filterTree
          .find((el) => el.label === "Billing Country")
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.selectOptions.find((el) => el.label === e.target.value) as any;

        setFilterTreeState({
          ...filterTreeState,
          billing_address: foundCountry?.id,
        });
      },
      clearSearch: () =>
        setFilterTreeState({ ...filterTreeState, billing_address: "" }),
    },
  ];

  if (!isPartner) {
    filterTreeStatic.push({
      label: "Partner name",
      value: "partner",
      type: "select",
      initialValue: "",
      selectOptions: [],
      setValue: (e: React.ChangeEvent<HTMLInputElement>) => {
        const foundPartner = filterTree
          .find((el) => el.label === "Partner name")
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.selectOptions.find((el) => el.label === e.target.value) as any;

        setFilterTreeState({
          ...filterTreeState,
          partner_name: foundPartner.id,
        });
      },
      clearSearch: () =>
        setFilterTreeState({ ...filterTreeState, partner_name: "" }),
    });
  }

  const [filterTree, setFilterTree] = useState(filterTreeStatic);

  const handleDateChange = (value) =>
    setFilterTreeState({
      ...filterTreeState,
      date: {
        interval: value.interval,
        startDate: value.date.startDate,
        endDate: value.date.endDate,
      },
    });

  const pairCharts = Object.values(pairs);

  const unit =
    filterTreeState.date.interval === "hour" ||
    filterTreeState.date.interval === "day"
      ? filterTreeState.date.interval
      : "day";

  return (
    <div className="revenue-charts-container">
      <div className="d-flex">
        <div className="top-filter-financial">
          <PresetsDatePicker
            onDateChange={handleDateChange}
            intervalPicker={true}
          >
            <div className="w-100" style={{ marginTop: "0px" }}>
              <FilterList
                secondLevelHandlers={filterTreeStatic.map((el) => el.setValue)}
                secondLevelFilter={filterTree}
                handleApply={() => console.log()}
                enableClearFilters={true}
                hideClearFilters={true}
                clearSearches={filterTreeStatic.map((el) => el.clearSearch)}
                additionalHandlers={[]}
                clearAdditionalSearches={[]}
                clearSelect={() => null}
              />
            </div>
          </PresetsDatePicker>
        </div>
      </div>
      <div className="charts-big-container revenue-charts-border">
        <Line
          unit={unit}
          infoContenet="Transcation Amount"
          title="Transcation Amount"
          progress={transactionAmount.growthPercentage}
          count={`${filterTreeState.fiat.sign} ${transactionAmount.currentTotal}`}
          data={{
            x: transactionAmount.totalByCurrency.map(
              (el: { date: string }) => el.date
            ),
            y: transactionAmount.totalByCurrency.map(
              (el: { total_amount: string }) => el.total_amount
            ),
          }}
        />
        <Line
          unit={unit}
          infoContenet="Revenue"
          title="Revenue"
          progress={revenue?.growthPercentage || 0}
          count={`${filterTreeState.fiat.sign} ${revenue?.currentTotal || 0}`}
          data={{
            x: revenue?.totalRevenue.map((el) => el.date) || [],
            y: revenue?.totalRevenue.map((el) => el.revenue.toFixed(5)) || [],
          }}
        />
      </div>
      <div className="charts-container revenue-charts-border">
        {pairCharts.map((items) => {
          const el = items[0];

          const totalAmount = items.reduce(
            (sum, current) => sum + Number(current.total_amount),
            0
          );

          const totalQuantity = items.reduce(
            (sum, current) => sum + Number(current.quantity),
            0
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const newCrypto: any = filterTree
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .find((el: any) => el.label === "Asset")
            ?.selectOptions.find(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (item: any) =>
                item.code ===
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ((el as any).asset === "BTC_TEST" ? "BTC" : el.asset)
            );

          const progress = growthResult.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item: any) => item.asset === el.asset
          )?.growthPercentage;

          return (
            <div key={el.asset} className="twice-width">
              <Line
                unit={unit}
                infoContenet={el.asset_name}
                title={`${el.asset_name}(${newCrypto?.core})/${filterTreeState.fiat.symbol}`}
                progress={progress}
                count={`${filterTreeState.fiat.sign} ${totalAmount}`}
                subCount={`Quantity ${Number(totalQuantity.toFixed(7))} ${
                  el.asset_name
                }`}
                data={{
                  x: items.map((el) => el.date),
                  y: items.map((el) => String(el.total_amount)),
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
